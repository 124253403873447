import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import './login.css';

const GoogleSignin = () => {
  const navigate = useNavigate();  
  const handleSuccess = (response) => {
    console.log(response); 
    const id_token = response.credential;
  
    axios.post(' https://indie-userauth.onrender.com/dj-rest-auth/google/', { id_token })
      .then(response => {
        console.log('Backend verification success:', response.data);
        navigate('/members'); // Navigate to /members upon successful login
      })
      .catch(error => {
        console.error('Backend verification failed:', error.response?.data);
        if (error.response?.status === 401) {
          console.error('Invalid token. Please try logging in again.');
        } else {
          console.error('An error occurred during verification. Please try again later.');
        }
      });
  };

  const handleError = (error) => {
    console.error('Google Sign-In Error:', error);
  };
  
  return (
    <div className="googlesignin">
      <GoogleLogin onSuccess={handleSuccess} onError={handleError}  />
    </div>
  );
}

export default GoogleSignin;
