import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import './passwordreset.css';  // Make sure the CSS is linked correctly
import axios from "axios";

const NewPassword = () => {
    const { uid, token } = useParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage("Passwords do not match!");
            return;
        }

        try {
            const response = await axios.post("https://indie-userauth.onrender.com/resetconfirm", {
                uid,
                token,
                new_password: password,
            });
            setMessage(response.data.message);
            navigate("/login");
        } catch (error) {
            setMessage("Error: " + (error.response.data.message || "An error occurred."));
        }
    };

    return (
        <div className="newpassword-container">
            <div className="newpassword">
                <h2>Reset Your Password</h2>
                <form onSubmit={handleSubmit} className="newpassword-form">
                    <div className="password-field">
                        <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="New Password"
                            required
                            className="newpassword-input"
                        />
                        <i
                            onClick={() => setShowPassword(!showPassword)}
                            className={`toggle-password fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                        />
                    </div>
                    <div className="password-field">
                        <input
                            type={showPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm Password"
                            required
                            className="newpassword-input"
                        />
                        <i
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            className={`toggle-password fa ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                        />
                    </div>
                    <button type="submit" className="newpassword-button">Reset Password</button>
                </form>
                {message && <p className="newpassword-message">{message}</p>}
            </div>
        </div>
    );
};

export default NewPassword;
